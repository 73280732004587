.link-with-underline {
    text-decoration: underline;
}

.no-underline {
    text-decoration: none !important;
}
.no-underline:hover {
    text-decoration: none !important;
}
.no-underline:focus {
    text-decoration: none !important;
}
.no-underline:active {
    text-decoration: none !important;
}
.no-underline a {
    text-decoration: none !important;
}
